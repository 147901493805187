import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Lifecycle of MIPs`}</h1>
    <h3>{`MIP Statuses`}</h3>
    <p><strong parentName="p">{`1. Conception:`}</strong>{` The lifecycle of a MIP begins when the MIP proposal is posted on the Maker forum. However, in order for a MIP to move to the next stage, it needs to satisfy the transition criteria (1) described below:`}</p>
    <ul>
      <li parentName="ul">{`Submitted to the MIPs Discourse Forum.`}</li>
      <li parentName="ul">{`Submitted to the MIPs Github repository (with a Pull Request created by the MIP Author or MIP Editor).`}</li>
      <li parentName="ul">{`The format must follow the appropriate MIP Template for its type.`}</li>
      <li parentName="ul">{`MIPs must be original or replacement versions of older MIPs (No repeats allowed).`}</li>
    </ul>
    <p><strong parentName="p">{`2. Approved by MIP Editor(s):`}</strong>{` This phase of a MIP’s life cycle is when the MIP Editor(s) confirms that the proposed MIP follows the correct structure and editorial criteria defined in the MIP template. If the criteria is not met, the MIP Editor(s) will provide an explanation to the MIP Author as to why and allow them to make the appropriate changes before reconsideration. If the criteria have been met, the MIP Editor(s) performs the following actions:`}</p>
    <ul>
      <li parentName="ul">{`The MIP is approved by a MIP Editor(s) and is assigned a formal MIP number.`}</li>
      <li parentName="ul">{`The PR is merged in by a MIP Editor(s).`}</li>
    </ul>
    <p><strong parentName="p">{`3. Request for Comments (RFC):`}</strong>{` This phase is when a MIP goes through a formal review period, including feedback from the community, further drafting and additions. The timeline for the RFC phase is defined by its Feedback Period and Frozen Period. In order to move to the next phase, it needs to satisfy the transition criteria listed below:`}</p>
    <ul>
      <li parentName="ul">{`MIP Author finalizes changes of the MIP, based on community feedback.`}</li>
      <li parentName="ul">{`MIPs have a Feedback Period of 3 months. The RFC phase lasts at least 3 months before the MIP can move to the next phase. `}</li>
      <li parentName="ul">{`MIPs have a Frozen Period of 1 month. MIPs must not have had any changes for the last 1 month before they move to the next phase.`}</li>
    </ul>
    <p><strong parentName="p">{`4. Fulfilled Feedback Period Requirements:`}</strong>{` This status is given once the MIP has fulfilled the defined Feedback Period and Frozen Period. After the MIP has waited out its Feedback Period and Frozen Period, it’s ready for Formal Submission. Note that the Feedback Period and Frozen Period can overlap.`}</p>
    <p><strong parentName="p">{`5. Formal Submission (FS)`}</strong>{` - This phase is when MIP Authors submit their complete MIP(s) to the Governance cycle by posting it to the formal submission forum category within the formal submission window of a governance cycle.`}</p>
    <ul>
      <li parentName="ul">{`A MIP can be re-submitted to the formal submission process a maximum of 2 additional times (3 total), without having to go through phase 1- 4 again, if it failed to pass due to legitimate external reasons (e.g. got bundled in a governance poll or executive vote with a controversial proposal - subject to the governance facilitators judgement).`}</li>
    </ul>
    <p><strong parentName="p">{`6. Approved by the Governance Facilitator(s)`}</strong>{`: This phase is when the MIP must be formally approved by the Governance Facilitators.   `}</p>
    <ul>
      <li parentName="ul">{`Once approved by the Governance Facilitator, the MIP will be included in the inclusion poll of the Governance cycle.`}</li>
      <li parentName="ul">{`If the MIP is not approved by the Governance Facilitator, it may be reconsidered at a later date to enter the Governance cycle.
`}</li>
    </ul>
    <p><strong parentName="p">{`7. Governance Cycle`}</strong>{`: This phase is when MKR holders vote on whether to include the MIP in the governance poll, ultimately determining whether or not the MIP can formally enter the governance cycle.`}</p>
    <ul>
      <li parentName="ul">{`Once approved for the governance poll, MKR holders determine whether to accept or reject the package of proposals in the governance poll and finally to ratify the result in the executive vote.  `}</li>
    </ul>
    <p><strong parentName="p">{`8. Executive Vote:`}</strong>{` This phase is when the MIP becomes officially ratified or not. Determined by MKR holders, the executive vote ultimately accepts or rejects the MIP.  `}</p>
    <p><strong parentName="p">{`9. Accepted/Rejected:`}</strong>{` The Executive vote results in either acceptance or rejection of the MIP. If passed, the MIP is officially accepted and is given the accepted status. If the executive vote fails to pass before expiring, the MIP is rejected.  `}</p>
    <ul>
      <li parentName="ul">{`As described in phase 5, a rejected MIP, can be resubmitted, and in some cases (if it was rejected for provable extraneous explanation) may be allowed to enter the next Governance cycle immediately.   `}</li>
    </ul>
    <h3>{`Other MIP Statuses:`}</h3>
    <p><strong parentName="p">{`Withdrawn`}</strong>{`: when a MIP Author withdraws their MIP proposal, such as when:`}</p>
    <ul>
      <li parentName="ul">{`A MIP may be withdrawn at any point before it enters the Governance cycle. `}</li>
      <li parentName="ul">{`Note that a withdrawn proposal can be taken over from the original Author with a simple transition facilitated by a MIP Editor(s) and the respective parties. If the original MIP Author ceases to be available, the MIP Editor(s) may proceed with the transfer of Authors.`}</li>
    </ul>
    <p><strong parentName="p">{`Deferred`}</strong>{`: when a proposal has been deemed as not ready or not a priority but can be re-proposed at a later date.  `}</p>
    <ul>
      <li parentName="ul">{`Request for Comments (RFC) - Forum poll/signal request rejects a MIP Proposal.`}</li>
    </ul>
    <p><strong parentName="p">{`Obsolete`}</strong>{`: when a proposal is no longer used or is out of date, such as:`}</p>
    <ul>
      <li parentName="ul">{`A MIP is replaced with a new proposal.`}</li>
      <li parentName="ul">{`A MIP has been deferred for over 6 months.`}</li>
      <li parentName="ul">{`A MIP Author has abandoned the proposal and no person has communicated willingness to take over MIP Author responsibility.`}</li>
      <li parentName="ul">{`A MIP has been replaced by a newer, more updated MIP Proposal.`}</li>
      <li parentName="ul">{`A MIP no longer makes sense to keep in consideration.`}</li>
    </ul>
    <h3>{`MIP Status Change Process:`}</h3>
    <p>{`A status change for a MIP is requested by the MIP Author and will be reviewed by the MIP Editor(s) to see if it meets the status criteria of the requested status change. If it does, the Editor(s) will change the status of the MIP and the Author may proceed with the next stage of the process. If it does not, the MIP Editor(s) will revert with highlighted issues, and the Author must fix the highlighted issues before requesting another status change.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      